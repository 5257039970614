<template>
  <div style="width:100%; position: relative; height: 100%;">
    <div class="wrapchat">
      <div style="height: 100px" class="livehead d-flex align-center px-2">
        <div class="pl-2">
          <v-icon large color="white">mdi-robot-love-outline </v-icon>
        </div>
        <div class="pl-2 Kanit">คุยกับบอท</div>
      </div>
      <div
        id="chatbox"
        class="pb-3"
        style="flex-grow: 1;  overflow-x: hidden;  overflow-y: auto; position: relative"
      >
        <div class="pa-3 chatboxinside">
          <transition-group name="list" tag="div">
            <template v-for="(message, index) in messages">
              <div :key="index" :class="`messageitem-` + message.actor">
                <!-- <div :class="message.actor == 'answer' ? `mr-2` : 'ml-2'">
                    <v-avatar size="40px" color="#fff">
                      <v-img
                        v-if="message.actor == 'answer'"
                        src="../assets/character.png"
                        style="width: 24px;"
                      ></v-img>
                      <v-icon color="grey" v-else size="30px">mdi-account</v-icon>
                    </v-avatar>
                  </div> -->

                <!-- <span>Box : {{ message }}</span> -->
                <div>
                  <message-bubble
                    :key="message.text"
                    @sendMessage="sendMessage"
                    :message="message"
                  >
                  </message-bubble>
                </div>
              </div>
            </template>
          </transition-group>
        </div>
      </div>

      <div>
        <div style="width: 100%">
          <v-card shaped>
            <v-text-field
              @keyup.enter="
                sendMessage({
                  title: input,
                  payload: input,
                })
              "
              hide-actions
              v-model="input"
              hide-details
              class="mb-0 py-1"
              placeholder="Type a message..."
              solo
              flat
            >
              <template v-slot:prepend>
                <UploadSingleFile v-model="uploadImage" @input="onUploadFile">
                  <v-btn
                    icon
                    color="#455A64"
                    style="transform: rotate(45deg); padding-bottom: 12px;"
                    plain
                    :ripple="false"
                  >
                    <v-icon>mdi-paperclip</v-icon>
                  </v-btn>
                </UploadSingleFile>
              </template>

              <template v-slot:append>
                <v-btn
                  @click="
                    sendMessage({
                      title: input,
                      payload: input,
                    })
                  "
                  icon
                  color="#0325A0"
                  style="transform: rotate(-30deg);"
                  plain
                  :ripple="false"
                >
                  <v-icon>mdi-send</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-card>
        </div>

        <!-- <div style="height: 20px; background-color: #fff"></div> -->
      </div>
      <perfect-scrollbar
        style="
          position: absolute;
          bottom: 60px;
          left: 0;
          padding-right: 12px;
          overflow-x: auto;
          height: 44px;
          overflow-y: hidden;
          display: flex;
          justify-content: left;
          align-items: end;
          z-index: 1;
          padding-bottom: 16px;
        "
        class="text-center quick-button"
      >
        <v-btn
          small
          @click="
            sendMessage({
              title: item,
              payload: item,
            });
            quick_replies = '';
          "
          dense
          v-ripple
          style="
            background-color: white;
            border: 1px solid rgb(239 147 5);
            border-radius: 12px;
            color: #EF9305;
            font-size: 11px;
          "
          class="mx-2 px-3 text-capitalize"
          v-for="(item, k) in quick_replies"
          :key="k"
          >{{ item }}</v-btn
        >
      </perfect-scrollbar>
    </div>
    <transition name="list">
      <div v-if="showToBottom" class="text-center tobottom">
        <v-btn @click="scrollToBottom" icon elevation="2" small>
          <v-icon>mdi-chevron-double-down</v-icon>
        </v-btn>
      </div>
    </transition>
  </div>
</template>

<script>
import Chatbox from "./Chatbox.js";
import UploadSingleFile from "@/components/UploadSingleFile.vue";
export default {
  mixins: [Chatbox],
  components: {
    UploadSingleFile,
  },
};
</script>

<style lang="scss" scoped>
.messageitem-question {
  display: flex;
  margin-bottom: 16px;
  flex-direction: row-reverse;
  .v-avatar {
    display: none;
  }
}

.messageitem-question .timestamp {
  text-align: right;
}

.messageitem-answer {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}
.livehead {
  background-color: #0325a0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: white;
  // padding: 13px;
  text-align: center;
  font-size: 24px;
  line-height: 26px;
  padding: 12px 0;
}
.wrapchat {
  position: relative;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(20px);
}

.tobottom {
  position: absolute;
  // transform: translate3d(0, 0, 0);
  bottom: 60px;
  right: 10px;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.chatboxinside {
  min-height: 500px;
  padding-bottom: 30px !important;
}

.quick-button {
  @media screen and (max-width: 960px) {
    width: 100%;
  }
  @media screen and (min-width: 961px) {
    width: 380px;
  }
}
</style>
