<template>
  <div>
    <div class="text-left">
      <!-- type: button -->
      <div class="bubble" v-if="message.type == 'button'">
        <div
          class="message"
          v-if="
            (message.text != '' && message.type == 'text') ||
              (message.type == 'button' &&
                message.buttons &&
                (message.buttons.length > 0 || message.text != ''))
          "
          style="
            width: 100%;
            word-break: break-word;
            position: relative;
            border-radius: 12px;
            width: 250px;
            overflow-wrap: break-word;
            min-height: 22px;
            min-width: 14px;
            line-height: 15.36px;
            overflow: hidden;
            white-space: initial;
          "
          :style="{ float: align }"
        >
          <div style="font-size: 14px" :style="{ color: color }">
            <div
              style="padding: 5px 8px 6px"
              :style="{ 'background-color': background }"
            >
              <span style="line-height: 15.36px; line-height: 22px">
                <template v-if="message.text != ''">
                  <specialtext :text="message.text"></specialtext>
                </template>
                <template v-else>-</template>
              </span>
            </div>
          </div>
          <div
            v-if="message.buttons && message.buttons.length > 0"
            style="width: 100%; background-color: #EFEEF4 !important; overflow: hidden"
            :style="{ float: align }"
          >
            <div style="margin-bottom: 0px;" class="py-2">
              <div
                class="py-1"
                style="
                  border-radius: 0px;
                  text-align: center;
                  padding: 2px 15px;
                "
                v-for="(button, bindex) in message.buttons"
                :key="bindex"
              >
                <template v-if="button">
                  <span style="color: #0084ff">
                    <v-btn
                      v-if="button.type == 'web_url'"
                      block
                      depressed
                      style="font-weight: bold; border-radius: 7px"
                      :href="updateQueryString(button.url)"
                      target="_blank"
                      rel="noopener noreferrer"
                      color="#CFE0FA"
                      >{{ button.title | truncate(20) }}
                    </v-btn>

                    <template v-else>
                      <v-btn
                        @click="clickActionButton(button)"
                        color="#CFE0FA"
                        block
                        depressed
                        style="font-weight: bold; border-radius: 7px"
                      >
                        {{ button.title | truncate(20) }}
                      </v-btn>
                    </template>
                  </span>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- type: image -->
      <div class="bubble" v-else-if="message.type == 'image'">
        <img
          v-if="message.type == 'image' && message.image_url"
          :src="message.image_url"
          style="max-width: 200px; cursor: pointer; padding-top: 8px; padding-bottom: 4px;"
          class="px-2"
          @click="showImg(message.image_url)"
        />

        <img
          v-if="message.type == 'image' && message.text"
          :src="message.text"
          style="max-width: 200px; cursor: pointer; padding-top: 8px; padding-bottom: 4px;"
          class="px-2"
          @click="showImg(message.text)"
        />
      </div>

      <!-- Video -->
      <div class="bubble" v-else-if="message.type == 'video'">
        <div style="max-width: 300px" class="pa-2">
          <video
            style="border-radius: 10px"
            width="100%"
            height="100%"
            controls
          >
            <source :src="message.source" type="video/mp4" />
            <source :src="message.source" type="video/ogg" />
          </video>
          <v-img :src="message.image_url"></v-img>
        </div>
      </div>

      <!-- type: text -->
      <div v-else>
        <div
          v-if="
            message.text !== '{image}' &&
              message.text !== 'welcome' &&
              message.type !== 'generic'
          "
          style="padding: 8px"
          class="bubble"
          v-linkified
        >
          {{ message.text }}
        </div>
      </div>

      <div
        style="font-size:10px; color: #77838F; padding-top: 4px;"
        class="timestamp"
        v-if="
          message.text !== '{image}' &&
            message.type !== 'generic' &&
            message.text !== 'welcome'
        "
      >
        {{ message.timestamp }}
      </div>
      <template v-if="message.type == 'generic'">
        <perfect-scrollbar
          :style="`width: ${windowSize}; max-width: 500px; overflow: auto`"
          class="pb-5"
        >
          <div style="display: flex; flex-direction: row">
            <v-card
              style="width: 250px; background-color: #EFEEF4"
              class="mr-2"
              v-for="(genericitem, k) in message.data"
              :key="k"
            >
              <div
                style="
                  display: flex;
                  height: 100%;
                  flex-direction: column;
                  justify-content: space-between;
                "
              >
                <div>
                  <div>
                    <div v-if="genericitem.image_url">
                      <v-img
                        @click="showImg(genericitem.image_url)"
                        style="cursor: pointer"
                        width="200px"
                        :src="genericitem.image_url"
                      ></v-img>
                    </div>
                  </div>
                  <div style="width: 200px"></div>
                  <div class="px-1">
                    <div class="px-3 pt-2">
                      <div
                        style="
                          font-weight: bold;
                          text-align: left;
                          font-size: 13px;
                        "
                      >
                        {{ genericitem.title }}
                      </div>
                      <div
                        style="
                          font-weight: light;
                          font-size: 13px;
                          text-align: left;
                          line-height: 15px;
                          color: #7b7b7b;
                        "
                      >
                        {{ genericitem.subtitle }}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="genericitem.buttons && genericitem.buttons.length > 0"
                  style="width: 100%; background-color: #e7e7e7"
                >
                  <div
                    style="margin-bottom: 0px; background-color: #EFEEF4;"
                    class="py-2"
                  >
                    <div
                      class="py-1"
                      style="
                        border-radius: 0px;
                        text-align: center;
                        padding: 2px 15px;
                      "
                      v-for="(button, bindex) in genericitem.buttons"
                      :key="bindex"
                    >
                      <template v-if="button">
                        <span style="color: #0084ff">
                          <v-btn
                            v-if="button.type == 'web_url'"
                            color="#CFE0FA"
                            block
                            depressed
                            style="font-weight: bold; border-radius: 7px"
                            :href="updateQueryString(button.url)"
                            target="_blank"
                            rel="noopener noreferrer"
                            >{{ button.title | truncate(20) }}
                          </v-btn>

                          <template v-else>
                            <v-btn
                              @click="clickActionButton(button)"
                              color="#CFE0FA"
                              block
                              depressed
                              style="font-weight: bold; border-radius: 7px"
                            >
                              {{ button.title | truncate(20) }}
                            </v-btn>
                          </template>
                        </span>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
          </div>
        </perfect-scrollbar>
        <div
          style="font-size:10px; color: #77838F; padding-top: 4px;"
          class="timestamp"
        >
          {{ message.timestamp }}
        </div>
      </template>
      <div>
        <v-dialog v-model="showImage" width="600px">
          <v-card style="position: relative" v-if="currentImg" class="pa-3">
            <v-img :src="currentImg"></v-img>
            <div style="position: absolute; right: 5px; top: 5px">
              <v-btn
                @click="showImage = false"
                small
                icon
                color="#EF9305"
                depressed
              >
                <v-icon small color="white">close</v-icon>
              </v-btn>
            </div>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import specialtext from "@/components/specialtext.vue";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import linkify from "vue-linkify";

export default {
  props: {
    message: [Object, Array, String],
    background: {
      type: String,
      default: "#EFEEF4",
    },
    color: {
      type: String,
      default: "black",
    },
    showDeep: {
      type: Boolean,
      default: true,
    },
    size: {
      default: "big",
    },
    showProfile: {
      default: false,
      type: Boolean,
    },
    align: {
      default: "left",
    },
  },
  data() {
    return {
      showImage: false,
      currentImg: "",
    };
  },
  components: {
    specialtext,
    PerfectScrollbar,
    linkify,
  },
  computed: {
    lg() {
      return this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
    windowSize() {
      let size = window.innerWidth;
      return size > 500 ? "500px" : `${size}px`;
    },
  },
  mounted() {
    this.reload();
  },
  methods: {
    showImg(img) {
      this.showImage = true;
      this.currentImg = img;
    },
    clickActionButton(button) {
      this.$emit("sendMessage", {
        title: button.title,
        payload: button.payload,
      });
    },
    goLinkBtn(link) {
      window.location.assign(link);
    },

    updateQueryString(uri) {
      if (uri) {
        let apptype = this.$route.query.c;
        return uri.replace("{gpf_apptype}", apptype);
      }
    },
    onCopy: function() {
      let vm = this;

      vm.showCopy = true;
      setTimeout(() => {
        vm.showCopy = false;
      }, 3000);
    },
    onCopyError: function() {},
    reload() {
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss">
.messageitem-question {
  .bubble {
    word-break: break-word;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 12px;

    font-size: 14px;
    display: inline-block;
    background-color: #cfe0fa !important;
    color: #000000;
    white-space: pre-wrap;
    max-width: 250px;
    width: 100%;
  }
  .timestamp {
    text-align: right !important;
  }
}
.messageitem-answer {
  .bubble {
    word-break: break-word;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;

    font-size: 14px;
    display: inline-block;
    background-color: #efeef4 !important;
    white-space: pre-wrap;
    max-width: 250px;
    width: 100%;
  }
  .timestamp {
    text-align: left !important;
  }
}

.messageitem-broadcast {
  .bubble {
    word-break: break-word;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;

    font-size: 14px;
    display: inline-block;
    background-color: #d5f1d7 !important;
    white-space: pre-wrap;
    max-width: 250px;
    width: 100%;
  }

  .timestamp {
    text-align: left !important;
  }
}

.list-group.vertical-steps > .list-group-item {
  border: none;
  border-left: 3px solid #ece5dd;
  box-sizing: border-box;
  border-radius: 0;
  counter-increment: step-counter;
  padding-left: 40px;
  padding-right: 0px;
  padding-bottom: 50px;
  padding-top: 0px;
  margin-top: -25px;

  &:first-child {
    margin-top: 0;
  }
}
</style>
