<template>
  <div>
    <template v-for="(node, n) in nodes">
      <div style="display: inline-block" v-if="node" :key="'word_' + n">
        <v-chip
          v-if="n % 2"
          small
          readonly
          color="primary"
          dark
          disabled
          class="special-text white--text"
          >{{ node }}</v-chip
        >

        <template v-else>
          <!-- <nl2br tag="span" :text="node" /> -->
          <div tag="span" v-html="$sanitize(node)" v-linkified></div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import Nl2br from "vue-nl2br";
import linkify from "vue-linkify";
import Vue from "vue";

Vue.directive("linkified", linkify);

export default {
  props: {
    text: [String],
    truncate: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Nl2br,
  },
  computed: {
    nodes: function () {
      var text = this.nl2br(this.text);
      if (this.truncate != 0) {
        text = this.$options.filters.truncate(text, this.truncate);
      }
      try {
        return text.split(/{[$]?([A-Za-zก-๙ _]+)}/);
      } catch (error) {
        return [];
      }
    },
  },
  methods: {
    nl2br(str, is_xhtml) {
      if (typeof str === "undefined" || str === null) {
        return "";
      }
      var breakTag =
        is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
      return (str + "").replace(
        /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
        "$1" + breakTag + "$2"
      );
    },
  },
};
</script>
