<template>
  <div>
    <div class="wrapper-chat">
      <chatbox></chatbox>
      <!-- <button @click='toggle = !toggle' class="chatIcon"></button>
      <div @click='toggle = !toggle' class="chatIcon">
        <v-img
          style="display: inline-block"
          width="50px"
          src="../assets/chatIcon.png"
        ></v-img>
      </div>

      <div v-show='toggle' class="modal-chatbox">
        <chatbox></chatbox>
      </div> -->
    </div>
  
</div>
</template>

<script>
import Chatbox from "./Chatbox.vue";
export default {
  components: {
    Chatbox,
  },
  data () {
    return {
      chatIcon : '../assets/chatIcon.png',
      toggle: false,
    }
  },
  computed: {
    lg() {
      return this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>

<style lang="scss" scoped>
// .wrapper {
//   position: relative;
//   width: 100px;
//   height: 100px;
// }
.chatIcon {
  position: absolute;
  bottom: 50px;
  right: 50px;
}
.modal-chatbox {
  
  position: absolute;
  bottom: 80px;
  right: 60px;
  height: 500px;
  width: 400px;
  overflow: auto;
  border: 1px solid #ccc;
}
</style>