var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%","position":"relative","height":"100%"}},[_c('div',{staticClass:"wrapchat"},[_c('div',{staticClass:"livehead d-flex align-center px-2",staticStyle:{"height":"100px"}},[_c('div',{staticClass:"pl-2"},[_c('v-icon',{attrs:{"large":"","color":"white"}},[_vm._v("mdi-robot-love-outline ")])],1),_c('div',{staticClass:"pl-2 Kanit"},[_vm._v("คุยกับบอท")])]),_c('div',{staticClass:"pb-3",staticStyle:{"flex-grow":"1","overflow-x":"hidden","overflow-y":"auto","position":"relative"},attrs:{"id":"chatbox"}},[_c('div',{staticClass:"pa-3 chatboxinside"},[_c('transition-group',{attrs:{"name":"list","tag":"div"}},[_vm._l((_vm.messages),function(message,index){return [_c('div',{key:index,class:"messageitem-" + message.actor},[_c('div',[_c('message-bubble',{key:message.text,attrs:{"message":message},on:{"sendMessage":_vm.sendMessage}})],1)])]})],2)],1)]),_c('div',[_c('div',{staticStyle:{"width":"100%"}},[_c('v-card',{attrs:{"shaped":""}},[_c('v-text-field',{staticClass:"mb-0 py-1",attrs:{"hide-actions":"","hide-details":"","placeholder":"Type a message...","solo":"","flat":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.sendMessage({
                title: _vm.input,
                payload: _vm.input,
              })}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('UploadSingleFile',{on:{"input":_vm.onUploadFile},model:{value:(_vm.uploadImage),callback:function ($$v) {_vm.uploadImage=$$v},expression:"uploadImage"}},[_c('v-btn',{staticStyle:{"transform":"rotate(45deg)","padding-bottom":"12px"},attrs:{"icon":"","color":"#455A64","plain":"","ripple":false}},[_c('v-icon',[_vm._v("mdi-paperclip")])],1)],1)]},proxy:true},{key:"append",fn:function(){return [_c('v-btn',{staticStyle:{"transform":"rotate(-30deg)"},attrs:{"icon":"","color":"#0325A0","plain":"","ripple":false},on:{"click":function($event){return _vm.sendMessage({
                    title: _vm.input,
                    payload: _vm.input,
                  })}}},[_c('v-icon',[_vm._v("mdi-send")])],1)]},proxy:true}]),model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}})],1)],1)]),_c('perfect-scrollbar',{staticClass:"text-center quick-button",staticStyle:{"position":"absolute","bottom":"60px","left":"0","padding-right":"12px","overflow-x":"auto","height":"44px","overflow-y":"hidden","display":"flex","justify-content":"left","align-items":"end","z-index":"1","padding-bottom":"16px"}},_vm._l((_vm.quick_replies),function(item,k){return _c('v-btn',{directives:[{name:"ripple",rawName:"v-ripple"}],key:k,staticClass:"mx-2 px-3 text-capitalize",staticStyle:{"background-color":"white","border":"1px solid rgb(239 147 5)","border-radius":"12px","color":"#EF9305","font-size":"11px"},attrs:{"small":"","dense":""},on:{"click":function($event){_vm.sendMessage({
            title: item,
            payload: item,
          });
          _vm.quick_replies = '';}}},[_vm._v(_vm._s(item))])}),1)],1),_c('transition',{attrs:{"name":"list"}},[(_vm.showToBottom)?_c('div',{staticClass:"text-center tobottom"},[_c('v-btn',{attrs:{"icon":"","elevation":"2","small":""},on:{"click":_vm.scrollToBottom}},[_c('v-icon',[_vm._v("mdi-chevron-double-down")])],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }