import axios from "axios";
import $ from "jquery";
import moment from "moment";
import MessageBubble from "./MessageBubble.vue";
export default {
  components: {
    MessageBubble,
  },
  computed: {
    moment() {
      return moment;
    },
    lg() {
      return this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
    chatLog() {
      var chat = JSON.parse(localStorage.getItem("chatlog"));
      // console.log(chat);
      return this.showChatLog(chat);
    },
    userid() {
      return this.$route.query.p;
    },
    token() {
      return this.$route.query.u;
    },
    pageid() {
      return this.$store.state.pageid;
    },
    userFirstName() {
      return this.$route.query.n;
    },
    apptype() {
      return this.$route.query.c;
    },
  },
  data() {
    return {
      input: "",
      quick_replies: "",
      prevHeight: 0,
      showToBottom: false,
      loading: false,
      imgtest:
        "https://cdn3.vectorstock.com/i/1000x1000/46/72/person-gray-photo-placeholder-man-material-design-vector-23804672.jpg",
      messages: [],
      uploadImage: "",
      refreshingTime: 15000,
      refreshMessages: null,
    };
  },
  beforeCreate() {
    if (this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
      this.$store.state.bgcolor = "#efefef";
    }
  },
  beforeDestroy() {
    this.$store.state.bgcolor = "white";
  },
  async mounted() {
    const vm = this;
    await vm.getChatlog();
    document.getElementById("chatbox").addEventListener("scroll", () => {
      var chatboxheight =
        $("#chatbox").prop("scrollHeight") -
        document.getElementById("chatbox").offsetHeight;
      var result = chatboxheight - $("#chatbox").scrollTop();
      if (result > 300) {
        this.showToBottom = true;
      } else {
        this.showToBottom = false;
      }
    });

    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    var chatLogData = JSON.parse(localStorage.getItem("chatlog"));
    var chat = this.showChatLog(chatLogData);
    this.messages = chat;

    // if (this.messages.length == 0) {
    // this.sendMessage({ title: "welcome", payload: "menu" });
    // }

    this.$nextTick(() => {
      this.scrollToBottom();
    });

    setTimeout(() => {
      this.onRefreshMessages();
    }, this.refreshingTime);
  },
  methods: {
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);
      element.style.height = this.prevHeight;
      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },

    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },

    scrollToBottom() {
      var chatbox = $("#chatbox");
      var offset = document.getElementById("chatbox").offsetHeight;
      chatbox.animate(
        {
          scrollTop: chatbox.prop("scrollHeight") - offset,
        },
        150
      );
    },
    logout() {
      localStorage.setItem("user", "");
      this.$router.push("Login");
    },
    sendMessage(message) {
      // console.log(message);
      // console.log('state : ',this.$store.state);

      const vm = this;
      if (message && message.payload.trim() !== "") {
        // console.log(message)
        // UploadImage
        if (message.title == "image") {
          var data = {
            gpfToken: vm.token,
            pageid: vm.pageid,
            userid: vm.userid,
            userFirstName: vm.userFirstName,
            apptype: vm.apptype,
            message: { data: message.payload, type: "image" },
          };

          vm.messages.push({
            actor: "question",
            type: "image",
            image_url: message.payload,
            timestamp: moment().format("DD/MM/YYYY HH:mm"),
          });
        } else {
          var data = {
            gpfToken: vm.token,
            pageid: vm.pageid,
            userid: vm.userid,
            userFirstName: vm.userFirstName,
            apptype: vm.apptype,
            message: { data: message.payload.trim(), type: "text" },
          };
          vm.messages.push({
            actor: "question",
            type: "text",
            text: message.title,
            timestamp: moment().format("DD/MM/YYYY HH:mm"),
          });
        }

        vm.input = "";
        vm.quick_replies = "";
        axios
          .post("https://webhook-enterprise.zwiz.app/webhookwebchat", data, {
            headers: {
              Authorization: "Bearer r313p6JWgEUv5Jd87UbxV9Ddos35j7gt",
            },
          })
          .then((response) => {
            if (response.data.responsecode == 200) {
              if (response.data.data && response.data.data.length) {
                vm.quick_replies = response.data.quick_replies
                  ? response.data.quick_replies.split(",")
                  : "";
                response.data.data.forEach((el) => {
                  vm.messages.push({
                    timestamp: moment().format("DD/MM/YYYY HH:mm"),
                    actor: "answer",
                    ...el,
                  });
                });
              }
              vm.input = "";
              vm.$nextTick(() => {
                vm.scrollToBottom();
              });
            } else {
              vm.$swal.fire({
                icon: "error",
                text: response.data.responsecode,
              });
            }
          });
      } else {
        vm.input = "";
      }
    },
    onUploadFile(imgUrl) {
      this.sendMessage({ title: "image", payload: imgUrl });
    },
    onRefreshMessages: function () {
      const vm = this;
      vm.refreshMessages = setInterval(function () {
        vm.getChatlog();
      }, vm.refreshingTime);
    },
    async getChatlog() {
      const vm = this;
      try {
        let response = await axios.get(
          "https://api-enterprise.zwiz.app/warroom/webchat/chatlog",
          {
            headers: {
              Authorization: "1357f80e8330b64ae40702kosumida25",
            },
            params: {
              pageid: vm.pageid,
              userid: vm.userid,
              offset: 0,
              limit: 15,
            },
          }
        );
        if (response.data.responsecode == 200) {
          localStorage.setItem("chatlog", JSON.stringify(response.data.data));
          let chatLogData = JSON.parse(localStorage.getItem("chatlog"));
          let chat = vm.showChatLog(chatLogData);
          vm.messages = chat;

          vm.$nextTick(() => {
            vm.scrollToBottom();
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    showChatLog(message) {
      var messages = [];
      if (message.length > 0) {
        message.sort((a, b) => {
          return a.id - b.id;
        });
        message.forEach((cl) => {
          if (cl.questiondata) {
            // console.log("question: "+cl.questiondata)
            messages.push({
              actor: "question",
              type: cl.questiontype,
              text: cl.questiondata,
              timestamp: moment(cl.timestamp).format("DD/MM/YYYY HH:mm"),
            });
          }

          if (cl.answerby) {
            if (cl.answerby == "admin") {
              var text, type;
              type = cl.messagetype;
              text = cl.messagedata;

              let tempText = JSON.parse(text);
              if (type == "json" && tempText[0].type === "text") {
                messages.push({
                  actor: "answer",
                  type: "text",
                  text: tempText[0].text,
                  timestamp: moment(cl.timestamp).format("DD/MM/YYYY HH:mm"),
                });
              } else {
                messages.push({
                  actor: "answer",
                  type: type,
                  text: text,
                  timestamp: moment(cl.timestamp).format("DD/MM/YYYY HH:mm"),
                });
              }

              // messages.push({
              //   actor: "answer",
              //   type: type,
              //   text: text,
              //   timestamp: moment(cl.timestamp).format("DD/MM/YYYY HH:mm"),
              // });
            }

            if (cl.answerby == "bot") {
              var text, type;
              var messagedata = [];
              if (cl.messagedata) {
                messagedata = JSON.parse(cl.messagedata);
                // type = messagedata[0].type;
                // text = messagedata[0].text;

                messagedata.forEach((el) => {
                  if (el.type == "text") {
                    messages.push({
                      actor: "answer",
                      type: el.type,
                      text: el.text,
                      timestamp: moment(cl.timestamp).format(
                        "DD/MM/YYYY HH:mm"
                      ),
                    });
                  }

                  if (el.type == "button") {
                    messages.push({
                      actor: "answer",
                      type: el.type,
                      text: el.text,
                      buttons: el.buttons,
                      timestamp: moment(cl.timestamp).format(
                        "DD/MM/YYYY HH:mm"
                      ),
                    });
                  }

                  if (el.type == "image") {
                    messages.push({
                      actor: "answer",
                      type: el.type,
                      image_url: el.image_url,
                      buttons: el.buttons,
                      timestamp: moment(cl.timestamp).format(
                        "DD/MM/YYYY HH:mm"
                      ),
                    });
                  }

                  if (el.type == "generic") {
                    messages.push({
                      actor: "answer",
                      type: el.type,
                      data: el.data,
                      timestamp: moment(cl.timestamp).format(
                        "DD/MM/YYYY HH:mm"
                      ),
                    });
                  }
                });
              }
            }
            if (cl.answerby == "broadcast") {
              var text, type;
              var messagedata = [];
              if (cl.messagedata) {
                if (this.isJsonString(cl.messagedata)) {
                  messagedata = JSON.parse(cl.messagedata);
                }

                messagedata.forEach((el) => {
                  switch (el.type) {
                    case "text":
                      messages.push({
                        actor: "broadcast",
                        type: el.type,
                        text: el.text,
                        timestamp: moment(cl.timestamp).format(
                          "DD/MM/YYYY HH:mm"
                        ),
                      });
                      break;
                    case "button":
                      messages.push({
                        actor: "broadcast",
                        type: el.type,
                        text: el.text,
                        buttons: messagedata[0].buttons,
                        timestamp: moment(cl.timestamp).format(
                          "DD/MM/YYYY HH:mm"
                        ),
                      });
                      break;
                    case "image":
                      messages.push({
                        actor: "broadcast",
                        type: el.type,
                        image_url: el.image_url,
                        buttons: messagedata[0].buttons,
                        timestamp: moment(cl.timestamp).format(
                          "DD/MM/YYYY HH:mm"
                        ),
                      });
                      break;
                    case "generic":
                      messages.push({
                        actor: "broadcast",
                        type: el.type,
                        data: el.data,
                        timestamp: moment(cl.timestamp).format(
                          "DD/MM/YYYY HH:mm"
                        ),
                      });
                      break;
                  }
                });
              }
            }
          }
        });
      }

      // console.log(messages);
      return messages;
    },
  },
};
